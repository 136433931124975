import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  TextField,
  Button,
  Typography,
  Box,
  Container,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

function Admin() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);
  const [deleteUsername, setDeleteUsername] = useState('');
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();

  const fetchUsers = async () => {
    try {
      const response = await axios.get('https://mapyg.ru/server/users', { withCredentials: true });
      setUsers(response.data);
    } catch (error) {
      console.error('Ошибка при получении пользователей:', error);
      setError('Не удалось загрузить пользователей.');
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleCreateUser = async () => {
    setLoading(true);
    setError(null);
    setMessage(null);

    try {
      const response = await axios.post('https://mapyg.ru/server/register', { username, password, isAdmin });
      setMessage(response.data);
      alert('Пользователь создан!');
      fetchUsers();
    } catch (error) {
      console.error('Ошибка при создании пользователя', error);
      if (error.response && error.response.status === 400) {
        setError('Пользователь уже существует.');
      } else {
        setError('Ошибка при создании пользователя. Пожалуйста, попробуйте еще раз.');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleFetchData = async () => {
    setLoading(true);
    setError(null);
    setMessage(null);

    try {
      const response = await axios.get('https://mapyg.ru/api/update-markers', { withCredentials: true });
      if (response.status === 200) {
        setMessage(response.data);
      } else {
        setError('Неожиданный статус ответа. Пожалуйста, попробуйте снова.');
      }
    } catch (error) {
      console.error('Ошибка при получении данных:', error);
      if (error.response) {
        if (error.response.status === 200) {
          setMessage('Обновление прошло успешно, но возникли некоторые проблемы с сетевым соединением.');
        } else {
          setError(`Ошибка при получении данных: ${error.response.data}. Пожалуйста, попробуйте снова.`);
        }
      } else {
        setError('Ошибка сети. Пожалуйста, проверьте подключение.');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = async () => {
    try {
      await axios.post('https://mapyg.ru/server/logout', {}, { withCredentials: true });
      navigate('/login');
    } catch (error) {
      console.error('Ошибка выхода:', error);
      setError('Ошибка выхода. Пожалуйста, попробуйте снова.');
    }
  };

  const handleGoToMap = () => {
    navigate('/map');
  };

  const handleDeleteUser = async () => {
    setLoading(true);
    setError(null);
    setMessage(null);

    if (deleteUsername === 'Admin') {
      setError('Пользователь "Admin" не может быть удален.');
      setLoading(false);
      return;
    }

    try {
      const response = await axios.delete(`https://mapyg.ru/server/users/${deleteUsername}`);
      setMessage(`Пользователь ${deleteUsername} успешно удален`);
      fetchUsers();
    } catch (error) {
      console.error('Ошибка при удалении пользователя', error);
      setError('Ошибка при удалении пользователя. Пожалуйста, попробуйте снова.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h2">
          MapYG
        </Typography>
        <Typography component="h1" variant="h5">
          Панель Админа
        </Typography>
        <Box component="form" noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="username"
            label="Имя пользователя"
            name="username"
            autoComplete="username"
            autoFocus
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Пароль"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <FormControlLabel
            control={<Checkbox checked={isAdmin} onChange={(e) => setIsAdmin(e.target.checked)} color="primary" />}
            label="Пользователь Админ?"
          />
          <Button
            type="button"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={handleCreateUser}
          >
            Создать пользователя
          </Button>

          {/* Выпадающий список пользователей */}
          <FormControl fullWidth sx={{ mt: 3, mb: 2 }}>
            <InputLabel id="user-select-label">Список пользователей</InputLabel>
            <Select
              labelId="user-select-label"
              id="user-select"
              value={deleteUsername}
              onChange={(e) => setDeleteUsername(e.target.value)}
            >
              {users.map((user) => (
                <MenuItem key={user.username} value={user.username}>
                  {user.username} - {user.isAdmin ? 'Админ' : 'Пользователь'}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Удаление пользователя */}
          <Button
            type="button"
            fullWidth
            variant="outlined"
            sx={{ mt: 3, mb: 2 }}
            onClick={handleDeleteUser}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Удалить пользователя'}
          </Button>
          <Button
            type="button"
            fullWidth
            variant="outlined"
            sx={{ mt: 3, mb: 2 }}
            onClick={handleFetchData}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Сделать обновление базы'}
          </Button>
          <Button
            type="button"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={handleGoToMap}
          >
            Карта
          </Button>
          {message && <Typography color="success.main">{message}</Typography>}
          {error && <Typography color="error">{error}</Typography>}
          <Button
            type="button"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={handleLogout}
          >
            Выйти
          </Button>
        </Box>
      </Box>
    </Container>
  );
}

export default Admin;
