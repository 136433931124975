import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  Drawer,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Collapse,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const MapPage = () => {
  const [projects, setProjects] = useState([]);
  const [boards, setBoards] = useState({});
  const [columns, setColumns] = useState({});
  const [cachedData, setCachedData] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [selectedBoards, setSelectedBoards] = useState({});
  const [selectedColumns, setSelectedColumns] = useState({});
  const [expandedProjects, setExpandedProjects] = useState({});
  const [expandedBoards, setExpandedBoards] = useState({});
  const [completedFilter, setCompletedFilter] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [mapInitialized, setMapInitialized] = useState(false);
  const [stats, setStats] = useState({ total: 0, inProgress: 0 });
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  const clustererRef = useRef(null);

  useEffect(() => {
    const loadYmaps = () => {
      return new Promise((resolve) => {
        if (window.ymaps) {
          console.log('Yandex Maps API already loaded.');
          resolve();
        } else {
          console.log('Loading Yandex Maps API...');
          const script = document.createElement('script');
          script.src =
            'https://api-maps.yandex.ru/2.1/?apikey=YOUR_YANDEX_API_KEY&lang=ru_RU';
          script.async = true;
          script.onload = () => {
            console.log('Yandex Maps API loaded.');
            resolve();
          };
          script.onerror = () => {
            console.error('Failed to load Yandex Maps API.');
          };
          document.head.appendChild(script);
        }
      });
    };

    const fetchAllDataAndInitMap = async () => {
      try {
        console.log('Fetching all data...');
        const projectsResponse = await axios.get('/api/api/projects');
        const projects = projectsResponse.data;
        console.log('Projects fetched:', projects);
        setProjects(projects);

        const boardsData = {};
        for (const project of projects) {
          const boardsResponse = await axios.get('/api/api/boards', {
            params: { projectId: project.id },
          });
          boardsData[project.id] = boardsResponse.data;
        }
        console.log('Boards fetched:', boardsData);
        setBoards(boardsData);

        const columnsData = {};
        for (const projectId in boardsData) {
          for (const board of boardsData[projectId]) {
            const columnsResponse = await axios.get('/api/api/columns', {
              params: { boardId: board.id },
            });
            columnsData[board.id] = columnsResponse.data;
          }
        }
        console.log('Columns fetched:', columnsData);
        setColumns(columnsData);

        const allTasks = []; // Здесь должна быть логика получения всех задач (если нужно)
        setCachedData(allTasks);
        console.log('All tasks set:', allTasks);

        await loadYmaps();
        if (window.ymaps && mapContainerRef.current) {
          console.log('Initializing map with Yandex API ready.');
          window.ymaps.ready(initMap);
        }
      } catch (error) {
        console.error('Error fetching data or initializing map:', error);
      }
    };

    fetchAllDataAndInitMap();
  }, []);

  useEffect(() => {
    if (mapInitialized && cachedData.length > 0) {
      console.log('Map is initialized. Updating markers...');
      updateMapMarkers(cachedData);
    } else {
      console.log('Map is initialized but no data available to update markers.');
    }
  }, [mapInitialized, cachedData]);

  const initMap = () => {
    if (mapContainerRef.current && window.ymaps) {
      console.log('Initializing map...');
      const centerOfMap = [55.751574, 37.573856];
      const map = new window.ymaps.Map(mapContainerRef.current, {
        center: centerOfMap,
        zoom: 5,
      });
      mapRef.current = map;

      const clusterer = new window.ymaps.Clusterer({
        preset: 'islands#invertedVioletClusterIcons',
      });
      clustererRef.current = clusterer;
      map.geoObjects.add(clusterer);

      setMapInitialized(true);
      console.log('Map initialized.');
    } else {
      console.error('Map container or ymaps not available.');
    }
  };

  const updateMapMarkers = (tasks) => {
    if (mapInitialized && window.ymaps && mapRef.current && clustererRef.current) {
      console.log('Updating map markers...', tasks);
      const clusterer = clustererRef.current;
      clusterer.removeAll();

      tasks.forEach((task) => {
        if (task.lat && task.lng) {
          const placemark = new window.ymaps.Placemark(
            [task.lat, task.lng],
            {
              balloonContent: `${task.projectTitle} / ${task.columnTitle} / ${task.title}`,
            },
            {
              iconColor: task.color,
            }
          );
          clusterer.add(placemark);
        }
      });

      if (clusterer.getBounds()) {
        mapRef.current.setBounds(clusterer.getBounds(), { checkZoomRange: true });
        console.log('Map bounds updated.');
      }
    } else {
      console.error('Map not initialized or references missing.');
    }
  };

  const loadMarkersWithFilters = () => {
    console.log('Loading markers with filters... Initial cached data:', cachedData);
    let filteredTasks = cachedData;

    if (selectedProjects.length > 0) {
      filteredTasks = filteredTasks.filter((task) =>
        selectedProjects.includes(task.projectId)
      );
      console.log('Filtered by projects:', filteredTasks);
    }

    if (Object.keys(selectedBoards).length > 0) {
      const selectedBoardIds = Object.values(selectedBoards).flat();
      filteredTasks = filteredTasks.filter((task) =>
        selectedBoardIds.includes(task.boardId)
      );
      console.log('Filtered by boards:', filteredTasks);
    }

    if (Object.keys(selectedColumns).length > 0) {
      const selectedColumnIds = Object.values(selectedColumns).flat();
      filteredTasks = filteredTasks.filter((task) =>
        selectedColumnIds.includes(task.columnId)
      );
      console.log('Filtered by columns:', filteredTasks);
    }

    if (completedFilter !== null) {
      filteredTasks = filteredTasks.filter(
        (task) => task.completed === (completedFilter === 'true')
      );
      console.log('Filtered by completion status:', filteredTasks);
    }

    updateMapMarkers(filteredTasks);
    calculateStats(filteredTasks);
  };

  const calculateStats = (tasks) => {
    console.log('Calculating stats for tasks:', tasks);
    const total = tasks.length;
    const inProgress = tasks.filter((task) => task.columnTitle === 'В РАБОТУ').length;
    setStats({ total, inProgress });
    console.log('Stats updated:', { total, inProgress });
  };

  const handleSelectAll = () => {
    console.log('Selecting all projects, boards, and columns.');
    const allProjectIds = projects.map((p) => p.id);
    setSelectedProjects(allProjectIds);

    const allBoards = {};
    for (const projectId in boards) {
      allBoards[projectId] = boards[projectId].map((b) => b.id);
    }
    setSelectedBoards(allBoards);

    const allColumns = {};
    for (const boardId in columns) {
      allColumns[boardId] = columns[boardId].map((c) => c.id);
    }
    setSelectedColumns(allColumns);
  };

  const handleDeselectAll = () => {
    console.log('Deselecting all projects, boards, and columns.');
    setSelectedProjects([]);
    setSelectedBoards({});
    setSelectedColumns({});
  };

  const handleProjectToggle = (projectId) => {
    console.log(`Toggling project ${projectId}.`);
    setExpandedProjects((prevState) => ({
      ...prevState,
      [projectId]: !prevState[projectId],
    }));
  };

  const handleToggleAllBoards = (projectId) => {
    console.log(`Toggling all boards for project ${projectId}.`);
    const allBoards = (boards[projectId] || []).map((board) => board.id);
    const selectedForProject = selectedBoards[projectId] || [];
    if (selectedForProject.length === allBoards.length) {
      setSelectedBoards((prevSelectedBoards) => ({
        ...prevSelectedBoards,
        [projectId]: [],
      }));
    } else {
      setSelectedBoards((prevSelectedBoards) => ({
        ...prevSelectedBoards,
        [projectId]: allBoards,
      }));
    }
  };

  const handleBoardToggle = (boardId) => {
    console.log(`Toggling board ${boardId}.`);
    setExpandedBoards((prevState) => ({
      ...prevState,
      [boardId]: !prevState[boardId],
    }));
  };

  const handleToggleAllColumns = (boardId) => {
    console.log(`Toggling all columns for board ${boardId}.`);
    const allColumns = (columns[boardId] || []).map((column) => column.id);
    const selectedForBoard = selectedColumns[boardId] || [];
    if (selectedForBoard.length === allColumns.length) {
      setSelectedColumns((prevSelectedColumns) => ({
        ...prevSelectedColumns,
        [boardId]: [],
      }));
    } else {
      setSelectedColumns((prevSelectedColumns) => ({
        ...prevSelectedColumns,
        [boardId]: allColumns,
      }));
    }
  };

  return (
    <Box>
      <Button
        onClick={() => setDrawerOpen(true)}
        className="filter-panel"
        variant="contained"
        sx={{ position: 'absolute', top: 16, left: 16, zIndex: 1000 }}
      >
        Фильтры
      </Button>
      <Drawer anchor="right" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
        <Box p={2} width={400}>
          <Typography variant="h6" gutterBottom>
            Фильтры
          </Typography>
          <Button onClick={handleSelectAll}>Выбрать все объекты</Button>
          <Button onClick={handleDeselectAll}>Снять выбор со всех объектов</Button>

          <FormControl fullWidth margin="normal">
            <InputLabel>Фильтр по статусу задачи</InputLabel>
            <Select
              value={completedFilter}
              onChange={(event) => setCompletedFilter(event.target.value)}
            >
              <MenuItem value={null}>Все задачи</MenuItem>
              <MenuItem value="true">Завершенные</MenuItem>
              <MenuItem value="false">Незавершенные</MenuItem>
            </Select>
          </FormControl>

          {projects.map((project) => (
            <Box key={project.id} mt={2}>
              <Button
                onClick={() => handleProjectToggle(project.id)}
                fullWidth
                variant="outlined"
                endIcon={
                  expandedProjects[project.id] ? <ExpandLessIcon /> : <ExpandMoreIcon />
                }
              >
                {project.title}
              </Button>
              <Collapse in={expandedProjects[project.id]}>
                <>
                  <FormControl fullWidth margin="normal">
                    <Button onClick={() => handleToggleAllBoards(project.id)}>
                      {selectedBoards[project.id] &&
                      selectedBoards[project.id].length ===
                        (boards[project.id] || []).length
                        ? 'Снять выбор со всех досок'
                        : 'Выбрать все доски'}
                    </Button>
                    <InputLabel>Выберите доски</InputLabel>
                    <Select
                      multiple
                      value={selectedBoards[project.id] || []}
                      onChange={(event) =>
                        setSelectedBoards((prevSelectedBoards) => ({
                          ...prevSelectedBoards,
                          [project.id]: event.target.value,
                        }))
                      }
                      renderValue={(selected) =>
                        selected
                          .map(
                            (id) => boards[project.id].find((b) => b.id === id)?.title
                          )
                          .join(', ')
                      }
                    >
                      {(boards[project.id] || []).map((board) => (
                        <MenuItem key={board.id} value={board.id}>
                          <Checkbox
                            checked={(selectedBoards[project.id] || []).includes(
                              board.id
                            )}
                          />
                          <ListItemText primary={board.title} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  {(boards[project.id] || []).map((board) => (
                    <Box key={board.id} ml={2}>
                      <Button
                        onClick={() => handleBoardToggle(board.id)}
                        fullWidth
                        variant="outlined"
                        endIcon={
                          expandedBoards[board.id] ? (
                            <ExpandLessIcon />
                          ) : (
                            <ExpandMoreIcon />
                          )
                        }
                      >
                        {board.title}
                      </Button>
                      <Collapse in={expandedBoards[board.id]}>
                        <FormControl fullWidth margin="normal">
                          <Button onClick={() => handleToggleAllColumns(board.id)}>
                            {selectedColumns[board.id] &&
                            selectedColumns[board.id].length ===
                              (columns[board.id] || []).length
                              ? 'Снять выбор со всех колонок'
                              : 'Выбрать все колонки'}
                          </Button>
                          <InputLabel>Выберите колонки</InputLabel>
                          <Select
                            multiple
                            value={selectedColumns[board.id] || []}
                            onChange={(event) =>
                              setSelectedColumns((prevSelectedColumns) => ({
                                ...prevSelectedColumns,
                                [board.id]: event.target.value,
                              }))
                            }
                            renderValue={(selected) =>
                              selected
                                .map(
                                  (id) =>
                                    columns[board.id].find((c) => c.id === id)?.title
                                )
                                .join(', ')
                            }
                          >
                            {(columns[board.id] || []).map((column) => (
                              <MenuItem key={column.id} value={column.id}>
                                <Checkbox
                                  checked={(selectedColumns[board.id] || []).includes(
                                    column.id
                                  )}
                                />
                                <ListItemText primary={column.title} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Collapse>
                    </Box>
                  ))}
                </>
              </Collapse>
            </Box>
          ))}
          <Button
            onClick={() => {
              loadMarkersWithFilters();
              setDrawerOpen(false);
            }}
            variant="contained"
            fullWidth
            sx={{ mt: 2 }}
          >
            Применить фильтры
          </Button>
          <Typography variant="h6" gutterBottom mt={2}>
            Статистика:
          </Typography>
          <Typography>Всего объектов на карте: {stats.total}</Typography>
          <Typography>Задач "В работе": {stats.inProgress}</Typography>
        </Box>
      </Drawer>
      <div
        id="mapContainer"
        ref={mapContainerRef}
        style={{
          height: '100vh',
          width: '100%',
          position: 'relative',
        }}
      ></div>
    </Box>
  );
};

export default MapPage;
